import img1 from '../images/testimonial/testimonial-01.jpg'
import avt1 from '../images/testimonial/avt-01.png'

const dataTestimonials = [
    {
        img: img1,
        text: 'Keniam, quis nostrud exerci ut aliquip ex ea com modo consequat. Duis aute irure dolor in reprehen derit in voluptate velit esse cillum dolore eu fugiat nulla parinon proident, sunt in culpa',
        avt: avt1,
        name: 'Paul Harrison,',
        postion: 'Founder & CEO'
    },
    {
        img: img1,
        text: 'Keniam, quis nostrud exerci ut aliquip ex ea com modo consequat. Duis aute irure dolor in reprehen derit in voluptate velit esse cillum dolore eu fugiat nulla parinon proident, sunt in culpa',
        avt: avt1,
        name: 'Paul Harrison,',
        postion: 'Founder & CEO'
    },
    {
        img: img1,
        text: 'Keniam, quis nostrud exerci ut aliquip ex ea com modo consequat. Duis aute irure dolor in reprehen derit in voluptate velit esse cillum dolore eu fugiat nulla parinon proident, sunt in culpa',
        avt: avt1,
        name: 'Paul Harrison,',
        postion: 'Founder & CEO'
    },
]

export default dataTestimonials;